import React, {useState, useEffect} from 'react'
import Toast from '../Toast'
import Button from '../Button'
import styled from 'styled-components'

const Wrapper = styled.div`
   {
    margin: 0 0 1.5em;
    display: ${props => (props.isVisible ? 'block' : 'none')};
  }
`

const LightButton = styled(Button)`
  color: var(--colorTertiaryDark);
  border-color: var(--colorTertiary);
`

const Component = () => {
  // local state
  const [isPristine, setIsPristine] = useState(true)
  const [showMessage, setShowMessage] = useState(false)

  // side effects
  useEffect(() => {
    if (isPristine === false) {
      setShowMessage(true)
    }

    const timer = setTimeout(() => {
      setShowMessage(false)
    }, 3 * 1000)

    // on unmount
    return () => {
      // cleanup
      clearTimeout(timer)
    }
  }, [isPristine])

  // event handlers
  const handleClick = e => {
    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Feedback Submitted',
      eventAction: 'click',
      eventLabel: e.target.value,
    })

    setIsPristine(false)
  }

  // render
  return (
    <>
      <Wrapper isVisible={isPristine}>
        <p>Ben je hiermee geholpen?</p>
        <LightButton light value="yes" onClick={handleClick}>
          Ja
        </LightButton>{' '}
        <LightButton light value="no" onClick={handleClick}>
          Nee
        </LightButton>
      </Wrapper>
      {isPristine === false && (
        <Toast visible={showMessage}>Bedankt voor je feedback</Toast>
      )}
    </>
  )
}

// class Component extends React.Component {
//   state = {pristine: true, showMessage: false}

//   componentWillUnmount() {
//     clearTimeout(this.hideTimer)
//   }
//   componentDidMount() {
//     window.dataLayer = window.dataLayer || []
//   }
//   render() {
//     const {pristine, showMessage} = this.state
//     return (
//       <Wrapper isVisible={pristine}>
//         <Toast visible={showMessage}>Bedankt voor je feedback</Toast>
//         <p>Ben je hiermee geholpen?</p>
//         <LightButton light value="yes" onClick={handleClick}>
//           Ja
//         </LightButton>{' '}
//         <LightButton light value="no" onClick={handleClick}>
//           Nee
//         </LightButton>
//       </Wrapper>
//     )
//   }
// }

export default Component
