import { graphql } from 'gatsby'
import React from 'react'
import Feedback from '../components/Feedback'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

const DetailTemplate = ({data}) => {
  const word = data.mongodbDeofhetWords
  return (
    <Layout>
      <Seo
        title={`🤔 de ${word.name} of het ${word.name}? Het antwoord hier...`}
        description={`de of het ${word.name}, welk lidwoord? De ${word.name} of het ${word.name}? Hier vind je het antwoord.`}
      />
      <div>
        <h1>{`De ${word.name} of het ${word.name}?`}</h1>
        <p>
          Welk lidwoord, de of het {word.name}? Is het{' '}
          {`de ${word.name} of het ${word.name}?`}{' '}
          <span role="img" aria-label="thinking face emoji">
            🤔
          </span>
        </p>
        <p>
          In het Nederlands bestaan er twee <em>bepalende</em> (ook wel:
          bepaalde) lidwoorden: <strong>de</strong> en <strong>het</strong>.{' '}
          <strong>De</strong> wordt gebruikt bij mannelijke en vrouwelijke
          woorden en bij meervouden, <strong>het</strong> bij onzijdige woorden
          in het enkelvoud. Welk bepalend lidwoord hoort bij {word.name}?
        </p>
        {/* <p>
          Is {word.name} mannelijk of vrouwelijk? Of is {word.name} een onzijdig
          woord?
        </p> */}
        <h2>
          Het antwoord is{' '}
          <span role="img" aria-label="party popper emoji">
            🎉
          </span>
        </h2>
        <p>
          Het antwoord is: <strong>{word.prefix}</strong>{' '}
          {word.name}
          {word.help ? ` ${word.help}.` : `.`}
        </p>
        <Feedback />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mongodbDeofhetWords(slug: {eq: $slug}) {
      name
      prefix
      help
    }
  }
`
export default DetailTemplate;
